import React from 'react'
import Layout from '../components/Layout'
import { Link } from 'gatsby'

const NotFoundPage = () => (
  <Layout>
    <section className="section">
      <div className="container">
        <h1 className="has-text-centered">PAGINA NIET GEVONDEN</h1>
        <p>
          Ga gewoon naar onze <Link to="/">homepagina</Link> om al onze diensten
          te ontdekken
        </p>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
